import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store' // or use a full path to ./store
import reset from '../store/index.js'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: 'login',
    },
    // {
    //     path: '/dashboard',
    //     name: 'dashboard',
    //     component: () =>
    //         import ('@/views/tickcats/dashboard.vue'),
    // },
    // {
    //     path: '/users',
    //     name: 'users',
    //     component: () =>
    //         import ('@/views/tickcats/users.vue'),
    // },
    // {
    //     path: '/users/add',
    //     name: 'user_add',
    //     component: () =>
    //         import ('@/views/tickcats/user_add.vue'),
    // },
    // // {
    // //     path: '/user_detail',
    // //     name: 'user_detail',
    // //     component: () =>
    // //         import ('@/views/tickcats/user_detail.vue'),
    // // },
    // {
    //     path: '/users/detail/:id',
    //     name: 'user_detail',
    //     component: () =>
    //         import ('@/views/tickcats/user_detail.vue'),
    // },
    // {
    //     path: '/organizer',
    //     name: 'organizer',
    //     component: () =>
    //         import ('@/views/tickcats/organizer.vue'),
    // },
    // {
    //     path: '/organizer/add',
    //     name: 'organizer_add',
    //     component: () =>
    //         import ('@/views/tickcats/organizer_add.vue'),
    // },
    // {
    //     path: '/organizer/detail/:id',
    //     name: 'organizer_detail',
    //     component: () =>
    //         import ('@/views/tickcats/organizer_detail.vue'),
    // },
    // {
    //     path: '/events',
    //     name: 'events',
    //     component: () =>
    //         import ('@/views/tickcats/events.vue'),
    // },
    // {
    //     path: '/events/add',
    //     name: 'event_add',
    //     component: () =>
    //         import ('@/views/tickcats/event_add.vue'),
    // },
    // {
    //     path: '/events/detail/:id',
    //     name: 'event_detail',
    //     component: () =>
    //         import ('@/views/tickcats/event_detail.vue'),
    // },
    // {
    //     path: '/addons',
    //     name: 'addons',
    //     component: () =>
    //         import ('@/views/tickcats/addons.vue'),
    // },
    // {
    //     path: '/orders',
    //     name: 'orders',
    //     component: () =>
    //         import ('@/views/tickcats/orders.vue'),
    // },
    // {
    //     path: '/resales',
    //     name: 'resales',
    //     component: () =>
    //         import ('@/views/tickcats/resales.vue'),
    // },
    // {
    //     path: '/reports',
    //     name: 'reports',
    //     component: () =>
    //         import ('@/views/tickcats/reports.vue'),
    // },
    // {
    //     path: '/tickettemp',
    //     name: 'tickettemp',
    //     component: () =>
    //         import ('@/views/tickcats/tickettemp.vue'),
    // },
    // {
    //     path: '/admins',
    //     name: 'admins',
    //     component: () =>
    //         import ('@/views/tickcats/admins.vue'),
    // },
    // {
    //     path: '/admins/add',
    //     name: 'admin_add',
    //     component: () =>
    //         import ('@/views/tickcats/admin_add.vue'),
    // },
    // // {
    // //     path: '/user_detail',
    // //     name: 'user_detail',
    // //     component: () =>
    // //         import ('@/views/tickcats/user_detail.vue'),
    // // },
    // {
    //     path: '/admins/detail/:id',
    //     name: 'admin_detail',
    //     component: () =>
    //         import ('@/views/tickcats/admin_detail.vue'),
    // },
    // {
    //     path: '/sitecontrol',
    //     name: 'sitecontrol',
    //     component: () =>
    //         import ('@/views/tickcats/sitecontrol.vue'),
    // },
    // {
    //     path: '/member',
    //     name: 'member',
    //     component: () =>
    //         import ('@/views/pages/member.vue'),
    //     meta: {
    //         title: '會員',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/new-staff',
    //     name: 'staff-add',
    //     component: () =>
    //         import ('@/views/pages/StaffAdd.vue'),
    //     meta: {
    //         title: '新增員工',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/staff-list',
    //     name: 'staff-list',
    //     component: () =>
    //         import ('@/views/pages/StaffList.vue'),
    //     meta: {
    //         title: '員工',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/member/detail/:id',
    //     name: 'member-detail',
    //     component: () =>
    //         import ('@/views/pages/member-detail.vue'),
    //     meta: {
    //         title: '會員詳細資料',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/member/add/',
    //     name: 'member-add',
    //     component: () =>
    //         import ('@/views/pages/member-detail.vue'),
    //     meta: {
    //         title: '新增會員',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/noti/detail/:id',
    //     name: 'noti-detail',
    //     component: () =>
    //         import ('@/views/pages/NotificationDetail.vue'),
    //     meta: {
    //         title: '通知詳細',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/noti/add/',
    //     name: 'noti-add',
    //     component: () =>
    //         import ('@/views/pages/NotificationDetail.vue'),
    //     meta: {
    //         title: '新增通知',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/member/apply/:id',
    //     name: 'member-apply',
    //     component: () =>
    //         import ('@/views/pages/member-apply.vue'),
    //     props: true,
    //     meta: {
    //         title: '會員報名',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/application',
    //     name: 'application',
    //     component: () =>
    //         import ('@/views/pages/application.vue'),
    //     meta: {
    //         title: '課程報名記錄',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/application/detail/:id',
    //     name: 'ApplicationDetail',
    //     component: () =>
    //         import ('@/views/pages/ApplicationDetail.vue'),
    //     meta: {
    //         title: '報名詳細資料',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/normal-application',
    //     name: 'normalApplication',
    //     component: () =>
    //         import ('@/views/pages/NormalApplication.vue'),
    //     meta: {
    //         title: '課程報名',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/normal-application/detail/:id',
    //     name: 'NormalApplicationDetail',
    //     component: () =>
    //         import ('@/views/pages/NormalApplicationDetail.vue'),
    //     meta: {
    //         title: '課程報名詳細資料',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/normal-application/detail/:id/:section',
    //     name: 'NormalApplicationDetailSection',
    //     component: () =>
    //         import ('@/views/pages/NormalApplicationDetail.vue'),
    //     meta: {
    //         title: '課程報名詳細資料',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/normal-application/redirect/:id/:section',
    //     name: 'NormalApplicationRedirect',
    //     component: () =>
    //         import ('@/views/pages/NormalApplicationRedirect.vue'),
    //     meta: {
    //         title: '課程報名詳細資料',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/extra-item',
    //     name: 'extra-item',
    //     component: () =>
    //         import ('@/views/pages/extra-item.vue'),
    //     meta: {
    //         title: '加購項目',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/extra-item/add',
    //     name: 'extra-item-add',
    //     component: () =>
    //         import ('@/views/pages/extra-item-add.vue'),
    //     meta: {
    //         title: '新增加購項目',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/extra-item/detail/:id',
    //     name: 'extra-item-detail',
    //     component: () =>
    //         import ('@/views/pages/extra-item-detail.vue'),
    //     meta: {
    //         title: '加購項目詳細',
    //         requireAuth: true,
    //     },
    // },

    // {
    //     path: '/coupon',
    //     name: 'coupon',
    //     component: () =>
    //         import ('@/views/pages/coupon.vue'),
    //     meta: {
    //         title: '優惠券',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/coupon/new-coupon',
    //     name: 'coupon-add',
    //     component: () =>
    //         import ('@/views/pages/CouponAdd.vue'),
    //     meta: {
    //         title: '新增優惠券',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/coupon/detail/:id',
    //     name: 'coupon-detail',
    //     component: () =>
    //         import ('@/views/pages/coupon-detail.vue'),
    //     meta: {
    //         title: '優惠券詳細資料',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/course',
    //     name: 'CourseSetting',
    //     component: () =>
    //         import ('@/views/pages/CourseSetting.vue'),
    //     meta: {
    //         title: '駕駛改進課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/course/detail/:id',
    //     name: 'CourseSettingDetail',
    //     component: () =>
    //         import ('@/views/pages/CourseEdit.vue'),
    //     meta: {
    //         title: '課程詳細資料',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/course/new-course',
    //     name: 'CourseAdd',
    //     component: () =>
    //         import ('@/views/pages/CourseEdit.vue'),
    //     meta: {
    //         title: '新增駕駛改進課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/timetable',
    //     name: 'LessonTimeTable',
    //     component: () =>
    //         import ('@/views/pages/LessonTimeTable.vue'),
    //     meta: {
    //         title: '駕駛改進課堂時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/lesson-add',
    //     name: 'LessonAdd',
    //     component: () =>
    //         import ('@/views/pages/LessonAdd.vue'),
    //     meta: {
    //         title: '新增課堂',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/timetable/detail/:id',
    //     name: 'LessonTimetableDetail',
    //     component: () =>
    //         import ('@/views/pages/LessonTimetableDetail.vue'),
    //     meta: {
    //         title: '課程詳細時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/written-test-timetable',
    //     name: 'WrittenTestTimetable',
    //     component: () =>
    //         import ('@/views/pages/WrittenTestTimetable.vue'),
    //     meta: {
    //         title: '筆試時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/written-test-reject',
    //     name: 'WrittenTestReject',
    //     component: () =>
    //         import ('@/views/pages/WrittenTestReject.vue'),
    //     meta: {
    //         title: '筆試待處理名單',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/written-course/detail/:id',
    //     name: 'WrittenCourseSettingDetail',
    //     component: () =>
    //         import ('@/views/pages/WrittenCourseSetting.vue'),
    //     meta: {
    //         title: '課程詳細資料',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/written-test/detail/:id',
    //     name: 'WrittenTestDetail',
    //     component: () =>
    //         import ('@/views/pages/WrittenTestDetail.vue'),
    //     meta: {
    //         title: '筆試詳細時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/create-written-test',
    //     name: 'CreateWrittenTest',
    //     component: () =>
    //         import ('@/views/pages/WrittenTestCreate.vue'),
    //     meta: {
    //         title: '建立筆試',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/create-written-test/:user_id/:lesson_id',
    //     name: 'CreateWrittenTestWithUserAndLessonID',
    //     component: () =>
    //         import ('@/views/pages/WrittenTestCreate.vue'),
    //     meta: {
    //         title: '建立筆試',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/force-test-timetable',
    //     name: 'ForceTestTimetable',
    //     component: () =>
    //         import ('@/views/pages/ForceTestTimetable.vue'),
    //     meta: {
    //         title: '強制試時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/force-test-reject',
    //     name: 'ForceTestReject',
    //     component: () =>
    //         import ('@/views/pages/ForceTestReject.vue'),
    //     meta: {
    //         title: '強制試待處理名單',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/force-test/detail/:id',
    //     name: 'ForceTestDetail',
    //     component: () =>
    //         import ('@/views/pages/ForceTestDetail.vue'),
    //     meta: {
    //         title: '強制試詳細時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/create-force-test',
    //     name: 'CreateForceTest',
    //     component: () =>
    //         import ('@/views/pages/ForceTestCreate.vue'),
    //     meta: {
    //         title: '建立強制試',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/road-test-timetable',
    //     name: 'RoadTestTimetable',
    //     component: () =>
    //         import ('@/views/pages/RoadTestTimetable.vue'),
    //     meta: {
    //         title: '路試時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/road-test-reject',
    //     name: 'RoadTestReject',
    //     component: () =>
    //         import ('@/views/pages/RoadTestReject.vue'),
    //     meta: {
    //         title: '路試待處理名單',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/road-course/detail/:id',
    //     name: 'RoadCourseSettingDetail',
    //     component: () =>
    //         import ('@/views/pages/RoadCourseSetting.vue'),
    //     meta: {
    //         title: '課程詳細資料',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/road-test/detail/:id',
    //     name: 'RoadTestDetail',
    //     component: () =>
    //         import ('@/views/pages/RoadTestDetail.vue'),
    //     meta: {
    //         title: '路試詳細時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/create-road-test',
    //     name: 'CreateRoadTest',
    //     component: () =>
    //         import ('@/views/pages/RoadTestCreate.vue'),
    //     meta: {
    //         title: '建立路試',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/create-road-test/:user_id/:lesson_id',
    //     name: 'CreateRoadTestWithUserAndLessonID',
    //     component: () =>
    //         import ('@/views/pages/RoadTestCreate.vue'),
    //     meta: {
    //         title: '建立路試',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/competence-test',
    //     name: 'CompetenceTest',
    //     component: () =>
    //         import ('@/views/pages/CompetenceTest.vue'),
    //     meta: {
    //         title: '強制試課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/create-competence-test',
    //     name: 'CreateCompetenceTest',
    //     component: () =>
    //         import ('@/views/pages/CompetenceTestCreate.vue'),
    //     meta: {
    //         title: '建立強制試課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/competence-test-classroom',
    //     name: 'CompetenceTestClassroom',
    //     component: () =>
    //         import ('@/views/pages/CompetenceTestClassroom.vue'),
    //     meta: {
    //         title: '課室',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/create-competence-test-classroom',
    //     name: 'CreateCompetenceTestClassroom',
    //     component: () =>
    //         import ('@/views/pages/CompetenceTestClassroomCreate.vue'),
    //     meta: {
    //         title: '建立課室',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/competence-test-timetable',
    //     name: 'CompetenceTestTimetable',
    //     component: () =>
    //         import ('@/views/pages/CompetenceTestTimetable.vue'),
    //     meta: {
    //         title: '強制試課程時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/road-test',
    //     name: 'RoadTest',
    //     component: () =>
    //         import ('@/views/pages/RoadTest.vue'),
    //     meta: {
    //         title: '正式路試日期',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/book-road-test',
    //     name: 'BookRoadTest',
    //     component: () =>
    //         import ('@/views/pages/RoadTestBook.vue'),
    //     meta: {
    //         title: '預約路試日期',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/book-road-test/:id',
    //     name: 'BookRoadTestWithWrittenTestID',
    //     component: () =>
    //         import ('@/views/pages/RoadTestBook.vue'),
    //     meta: {
    //         title: '預約路試日期',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/setting/general',
    //     name: 'GeneralSetting',
    //     component: () =>
    //         import ('@/views/pages/GeneralSetting.vue'),
    //     meta: {
    //         title: '基本設定',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/setting/test_table',
    //     name: 'TestTable',
    //     component: () =>
    //         import ('@/views/pages/TestTable.vue'),
    //     meta: {
    //         title: '更表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/enquiry',
    //     name: 'Enquiry',
    //     component: () =>
    //         import ('@/views/pages/enquiry.vue'),
    //     meta: {
    //         title: '查詢',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/written_question',
    //     name: 'WrittenQuestion',
    //     component: () =>
    //         import ('@/views/pages/WrittenQuestion.vue'),
    //     meta: {
    //         title: '筆試考題管理',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/notification',
    //     name: 'Notification',
    //     component: () =>
    //         import ('@/views/pages/Notification.vue'),
    //     meta: {
    //         title: '推送通知',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/reports',
    //     name: 'Reports',
    //     component: () =>
    //         import ('@/views/pages/Reports.vue'),
    //     meta: {
    //         title: '報表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/shift/shift_code',
    //     name: 'ShiftCode',
    //     component: () =>
    //         import ('@/views/pages/ShiftCode.vue'),
    //     meta: {
    //         title: '排更代碼',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/report',
    //     name: 'Report',
    //     component: () =>
    //         import ('@/views/pages/Report.vue'),
    //     meta: {
    //         title: '報表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/shift/shift_code/add',
    //     name: 'ShiftCodeDetailAdd',
    //     component: () =>
    //         import ('@/views/pages/ShiftCodeDetailAdd.vue'),
    //     meta: {
    //         title: '排更代碼',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/shift/shift_code/edit/:id',
    //     name: 'ShiftCodeDetail',
    //     component: () =>
    //         import ('@/views/pages/ShiftCodeDetail.vue'),
    //     meta: {
    //         title: '排更代碼',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/shift/shift_control',
    //     name: 'ShiftControl',
    //     component: () =>
    //         import ('@/views/pages/ShiftControl.vue'),
    //     meta: {
    //         title: '排更管理',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/shift/shift_ppl_stat',
    //     name: 'ShiftPplStat',
    //     component: () =>
    //         import ('@/views/pages/ShiftPplStat.vue'),
    //     meta: {
    //         title: '排更人數統計',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/shift/shift_hr_stat',
    //     name: 'ShiftHrStat',
    //     component: () =>
    //         import ('@/views/pages/ShiftHrStat.vue'),
    //     meta: {
    //         title: '排更鐘數統計',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/shift/shift_daily_hr',
    //     name: 'ShiftDailyHr',
    //     component: () =>
    //         import ('@/views/pages/ShiftDailyHr.vue'),
    //     meta: {
    //         title: '每日排更鐘數',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/practice',
    //     name: 'Practice',
    //     component: () =>
    //         import ('@/views/pages/Practice.vue'),
    //     meta: {
    //         title: '筆試考題',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/practice/add',
    //     name: 'PracticeDetailAdd',
    //     component: () =>
    //         import ('@/views/pages/PracticeDetailAdd.vue'),
    //     meta: {
    //         title: '新增考題',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/practice/edit/:id',
    //     name: 'PracticeDetail',
    //     component: () =>
    //         import ('@/views/pages/PracticeDetail.vue'),
    //     meta: {
    //         title: '考題內容',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/setting/address',
    //     name: 'AddressSetting',
    //     component: () =>
    //         import ('@/views/pages/AddressSetting.vue'),
    //     meta: {
    //         title: '課程地址',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/setting/address/add',
    //     name: 'AddressSettingAdd',
    //     component: () =>
    //         import ('@/views/pages/AddressSettingAdd.vue'),
    //     meta: {
    //         title: '新增課程地址',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/setting/address/edit/:id',
    //     name: 'AddressSettingEdit',
    //     component: () =>
    //         import ('@/views/pages/AddressSettingEdit.vue'),
    //     meta: {
    //         title: '課程地址詳細資料',
    //         requireAuth: true,
    //     },
    // },

    // {
    //     path: '/theory-course',
    //     name: 'TheoryCourse',
    //     component: () =>
    //         import ('@/views/pages/TheoryCourse.vue'),
    //     meta: {
    //         title: '理論課課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/theory-course-classroom',
    //     name: 'TheoryCourseClassroom',
    //     component: () =>
    //         import ('@/views/pages/TheoryCourseClassroom.vue'),
    //     meta: {
    //         title: '理論課課室',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/create-theory-course',
    //     name: 'CreateTheoryCourse',
    //     component: () =>
    //         import ('@/views/pages/TheoryCourseCreate.vue'),
    //     meta: {
    //         title: '建立理論課課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/mock-course',
    //     name: 'MockCourse',
    //     component: () =>
    //         import ('@/views/pages/MockCourse.vue'),
    //     meta: {
    //         title: '模擬考試課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/mock-course-classroom',
    //     name: 'MockCourseClassroom',
    //     component: () =>
    //         import ('@/views/pages/MockCourseClassroom.vue'),
    //     meta: {
    //         title: '模擬考試課室',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/create-mock-course',
    //     name: 'CreateMockCourse',
    //     component: () =>
    //         import ('@/views/pages/MockCourseCreate.vue'),
    //     meta: {
    //         title: '建立模擬考試課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/add-motor',
    //     name: 'CarAddMotor',
    //     component: () =>
    //         import ('@/views/pages/CarAddMotor.vue'),
    //     meta: {
    //         title: '新增電單車',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/add-van',
    //     name: 'CarAddVan',
    //     component: () =>
    //         import ('@/views/pages/CarAddVan.vue'),
    //     meta: {
    //         title: '新增輕型貨車',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/add-vehicle',
    //     name: 'CarAddVehicle',
    //     component: () =>
    //         import ('@/views/pages/CarAddVehicle.vue'),
    //     meta: {
    //         title: '新增私家車',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/manage-motor',
    //     name: 'CarManageMotor',
    //     component: () =>
    //         import ('@/views/pages/CarManageMotor.vue'),
    //     meta: {
    //         title: '電單車',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/manage-van',
    //     name: 'CarManageVan',
    //     component: () =>
    //         import ('@/views/pages/CarManageVan.vue'),
    //     meta: {
    //         title: '輕型貨車',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/manage-vehicle',
    //     name: 'CarManageVehicle',
    //     component: () =>
    //         import ('@/views/pages/CarManageVehicle.vue'),
    //     meta: {
    //         title: '私家車',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/typography',
    //     name: 'typography',
    //     component: () =>
    //         import ('@/views/typography/Typography.vue'),
    // },
    // {
    //     path: '/icons',
    //     name: 'icons',
    //     component: () =>
    //         import ('@/views/icons/Icons.vue'),
    // },
    // {
    //     path: '/cards',
    //     name: 'cards',
    //     component: () =>
    //         import ('@/views/cards/Card.vue'),
    // },
    // {
    //     path: '/simple-table',
    //     name: 'simple-table',
    //     component: () =>
    //         import ('@/views/simple-table/SimpleTable.vue'),
    // },
    // {
    //     path: '/form-layouts',
    //     name: 'form-layouts',
    //     component: () =>
    //         import ('@/views/form-layouts/FormLayouts.vue'),
    // },
    // {
    //     path: '/pages/account-settings',
    //     name: 'pages-account-settings',
    //     component: () =>
    //         import ('@/views/pages/account-settings/AccountSettings.vue'),
    // },
    // {
    //     path: '/welcome-course',
    //     name: 'WelcomeCourse',
    //     component: () =>
    //         import ('@/views/pages/WelcomeCourse.vue'),
    //     meta: {
    //         title: '電單車駕駛課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/welcome-course/new',
    //     name: 'CreateWelcomeCourse',
    //     component: () =>
    //         import ('@/views/pages/WelcomeCourseEdit.vue'),
    //     meta: {
    //         title: '建立電單車課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/welcome-course/detail/:id',
    //     name: 'WelcomeCourseDetail',
    //     component: () =>
    //         import ('@/views/pages/WelcomeCourseEdit.vue'),
    //     meta: {
    //         title: '電單車課程詳細資料',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/welcome-lesson-add',
    //     name: 'WelcomeLessonAdd',
    //     component: () =>
    //         import ('@/views/pages/WelcomeLessonAdd.vue'),
    //     meta: {
    //         title: '新增電單車課堂',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/welcome-lesson/detail/:id',
    //     name: 'WelcomeLessonDetail',
    //     component: () =>
    //         import ('@/views/pages/WelcomeLessonEdit.vue'),
    //     meta: {
    //         title: '迎新課堂時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/view-welcome-lessons',
    //     name: 'ViewWelcomeLessons',
    //     component: () =>
    //         import ('@/views/pages/WelcomeLessonTimetable.vue'),
    //     meta: {
    //         title: '迎新課堂時間表',
    //         requireAuth: true,
    //     },
    // },

    // {
    //     path: '/welcome-car-course',
    //     name: 'WelcomeCarCourse',
    //     component: () =>
    //         import ('@/views/pages/WelcomeCarCourse.vue'),
    //     meta: {
    //         title: '汽車駕駛課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/welcome-car-course/new',
    //     name: 'CreateWelcomeCarCourse',
    //     component: () =>
    //         import ('@/views/pages/WelcomeCarCourseEdit.vue'),
    //     meta: {
    //         title: '建立汽車課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/welcome-car-course/detail/:id',
    //     name: 'WelcomeCarCourseDetail',
    //     component: () =>
    //         import ('@/views/pages/WelcomeCarCourseEdit.vue'),
    //     meta: {
    //         title: '汽車課程詳細資料',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/welcome-car-lesson-add',
    //     name: 'WelcomeCarLessonAdd',
    //     component: () =>
    //         import ('@/views/pages/WelcomeCarLessonAdd.vue'),
    //     meta: {
    //         title: '新增汽車課堂',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/welcome-car-lesson/detail/:id',
    //     name: 'WelcomeCarLessonDetail',
    //     component: () =>
    //         import ('@/views/pages/WelcomeCarLessonEdit.vue'),
    //     meta: {
    //         title: '迎新課堂時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/view-welcome-car-lessons',
    //     name: 'ViewWelcomeCarLessons',
    //     component: () =>
    //         import ('@/views/pages/WelcomeCarLessonTimetable.vue'),
    //     meta: {
    //         title: '迎新課堂時間表',
    //         requireAuth: true,
    //     },
    // },

    // {
    //     path: '/motor-course',
    //     name: 'MotorCourse',
    //     component: () =>
    //         import ('@/views/pages/MotorCourse.vue'),
    //     meta: {
    //         title: '電單車課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/create-motor-course',
    //     name: 'CreateMotorCourse',
    //     component: () =>
    //         import ('@/views/pages/MotorCourseCreate.vue'),
    //     meta: {
    //         title: '建立電單車課程',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/add-motor-lesson',
    //     name: 'AddMotorLesson',
    //     component: () =>
    //         import ('@/views/pages/MotorLessonAdd.vue'),
    //     meta: {
    //         title: '新增電單車課堂',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/view-motor-lessons',
    //     name: 'ViewMotorLessons',
    //     component: () =>
    //         import ('@/views/pages/MotorLessonTimetable.vue'),
    //     meta: {
    //         title: '電單車課堂時間表',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/add-car-lesson',
    //     name: 'AddCarLesson',
    //     component: () =>
    //         import ('@/views/pages/CarLessonAdd.vue'),
    //     meta: {
    //         title: '新增汽車課堂',
    //         requireAuth: true,
    //     },
    // },
    // {
    //     path: '/view-car-lessons',
    //     name: 'ViewCarLessons',
    //     component: () =>
    //         import ('@/views/pages/CarLessonTimetable.vue'),
    //     meta: {
    //         title: '汽車課堂時間表',
    //         requireAuth: true,
    //     },
    // },
    {
        path: '/login',
        name: 'pages-login',
        component: () =>
            import ('@/views/pages/Login.vue'),
        meta: {
            title: '登入',
            layout: 'blank',
            requireAuth: false,
        },
    },
    // {
    //     path: '/pages/register',
    //     name: 'pages-register',
    //     component: () =>
    //         import ('@/views/pages/Register.vue'),
    //     meta: {
    //         layout: 'blank',
    //     },
    // },
    {
        path: '/error-404',
        name: 'error-404',
        component: () =>
            import ('@/views/Error.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

const DEFAULT_TITLE = ' - '

export default router