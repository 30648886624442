require('./overrides.scss')

export default {
    theme: {
        themes: {
            light: {
                primary: '#8A8D93',
                accent: '#0d6efd',
                secondary: '#8A8D93',
                success: '#56CA00',
                info: '#16B1FF',
                warning: '#FFB400',
                error: '#FF4C51',
            },
            dark: {
                primary: '#8A8D93',
                accent: '#0d6efd',
                secondary: '#8A8D93',
                success: '#56CA00',
                info: '#16B1FF',
                warning: '#FFB400',
                error: '#FF4C51',
            },
        },
    },
}